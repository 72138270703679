<template>
  <form class="upload-file" @submit.prevent="">
    <div class="upload-box">
      <span>(click or drag here)</span>
      <input ref="uploadField" type="file" multiple @change="uploadFiles" />
    </div>
  </form>
</template>
<script>
import FileUpload from './FileUpload';
export default {
  methods: {
    uploadFiles($e) {
      var files=this.$refs.uploadField.files;
      for(var i=0;i<files.length;i++)
        FileUpload.start(files[i]);
    },
  }
}
</script>
