<template>
<ul class="file-list">
  <li v-for="file in files" :class="{done: file.done}">
    <span class="name">{{ file.file.name }}</span>
    <span class="progress">{{ progress(file) }}</span>
    <span class="size">{{ formatSize(file.file.size) }}</span>
<!--     <a href="" v-on:click.prevent="stop(file)" v-if="!file.done">Stop</a> -->
  </li>
</ul>
</template>
<script>
import api from './api';
import FileUpload from './FileUpload';

export default {
  data: ()=>({
    files: [],
  }),
  methods: {
    formatSize(value) {
      if (value<1024)
        return value+" B";
      value/=1024;
      if (value<1024)
        return value.toFixed(0)+" kB";
      value/=1024;
      if (value<1024)
        return value.toFixed(2)+" MB";
      value/=1024;
      return value.toFixed(2)+" GB";
    },
    updateProgress(item) {
      if (!this.files.find(a=>a.id==item.id))
        this.files.push({...item});
      var file=this.files.find(a=>a.id==item.id);
      file.progress=item.progress;
      file.done=item.done;
    },
    progress(item) {
      if (item.done)
        return "done";
      var progress=item.progress.reduce((sum,item)=>sum+item[1], 0);
      var size=item.file.size;
      return Math.floor(progress/size*100)+"%";
    },
    stop(item) {
      this.files.splice(this.files.findIndex(i=>i.id==item.id), 1);
      FileUpload.stop(item);
    },
    async loadFiles(item) {
      this.files=(await api.list()).map(a=>({...a, file: a}));
    },
  },
  mounted() {
    window.appUploadProgress=this;
    this.loadFiles();
  },
}
</script>
