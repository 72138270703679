<template>
<header>
  <h1>Upload</h1>
</header>
<section class="upload-form">
  <upload-file />
</section>
<section class="upload-progress">
  <upload-progress />
</section>
<footer>
</footer>
</template>

<script>
export default {
  methods: {
  }
}
</script>
