import api from './api';


var queue=[];
var uploading=null;

var serialize=function(obj) {
  var str=[];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}


var videoUploader={
  support: ((typeof(File)!=='undefined') && (typeof(Blob)!=='undefined') && (typeof(FileList)!=='undefined') && (!!Blob.prototype.webkitSlice||!!Blob.prototype.mozSlice||!!Blob.prototype.slice||false)),
  fileSize: 0,
  uploads: [],
  chunkSize: 1024*1024*10,
  parallel: 3,
  urlUpload: "/api/upload",
  progressCb: null,
  videoId: null,
  uploadFormData(data, chunk, index) {
    return new Promise((resolve, reject)=>{
      var error=false;
      var request = new XMLHttpRequest();
      
      request.open("POST", videoUploader.urlUpload+"?"+serialize(data));
      request.onreadystatechange=function() {
        if (request.readyState==4) {
          if (request.status==0)
            reject();
          else
            resolve();
        }
      };
      request.onerror=(err)=>{
        error=err;
      };
      request.upload.addEventListener("progress", function(e) {
        videoUploader.uploads[index]=[e.loaded, e.total];
        videoUploader.progressCb && videoUploader.progressCb(videoUploader);
      });
      request.setRequestHeader("Content-Type", "application/octet-stream");
      request.send(chunk);
    });
  },
  async uploadChunk(file, chunkIndex) {
    var data={
      id: this.videoId,
      o: chunkIndex*videoUploader.chunkSize,
    };
    var chunk=file.slice(chunkIndex*videoUploader.chunkSize, (chunkIndex+1)*videoUploader.chunkSize);
    await videoUploader.uploadFormData(data, chunk, chunkIndex);
  },
  async uploadFile(file) {
    videoUploader.uploads=[];
    videoUploader.fileSize=file.size;
    var chunks=Math.ceil(file.size/videoUploader.chunkSize);
    var chunksToUpload=[];
    for(var i=0;i<chunks;i++) {
      chunksToUpload.push(i);
    }
    
    var currentUploads=[];
    
    var uploadParallel=function() {
      return new Promise((resolve, reject)=>{
        var doUpload=()=>{
          if (!chunksToUpload.length && !currentUploads.length)
            return resolve();
          if (!chunksToUpload.length)
            return ;
          if (currentUploads.length>=videoUploader.parallel)
            return;
          var nextIndex=chunksToUpload.shift();
          var nextUpload=videoUploader.uploadChunk(file, nextIndex);
          currentUploads.push(nextUpload);
          nextUpload.then(()=>{
            var ci=currentUploads.indexOf(nextUpload);
            currentUploads.splice(ci, 1);
            doUpload();
          }).catch((error)=>{
            reject();
          });
          doUpload();
        }
        doUpload();
      });
    };
    
    await uploadParallel(file, chunksToUpload);

  },
}



var upload={
  handleQueue() {
    if (uploading || !queue.length)
      return;
    uploading=queue.shift();
    this.handleUpload();
  },
  async handleUpload() {
//     console.log(uploading.file, JSON.parse(JSON.stringify(uploading)));
    uploading.uploading=true;
    videoUploader.progressCb=()=>{
      uploading.progress=videoUploader.uploads.map((a,i)=>[i*videoUploader.chunkSize, a[0]]);
      appUploadProgress.updateProgress(uploading);
    };
    videoUploader.videoId=uploading.id;
    await videoUploader.uploadFile(uploading.file);
    uploading.done=true;
    uploading.uploading=false;
    appUploadProgress.updateProgress(uploading);
    uploading=null;
    this.handleQueue();
  },
  async start(file) {
//     console.log(file);
    try {
      var item={
        file,
        id: null,
        progress: [],
        done: false,
        uploading: false,
      };
      var result=await api.start(file, (progress, done)=>{
        item.progress=progress;
        item.done=done;
      });
      item.id=result.id;
      queue.push(item);
      this.handleQueue();
      window.appUploadProgress && window.appUploadProgress.updateProgress(item);
    } catch(e) { console.log(e); }
  },
  async remote(url) {
    try {
      var item={
        file,
        id: null,
        progress: [],
        done: false,
        uploading: false,
      };
      var result=await api.remote(file, (progress, done)=>{
        item.progress=progress;
        item.done=done;
      });
      item.id=result.id;
    } catch(e) { console.log(e); }
  },
  stop(item) {
    if (item.uploading) {
    } else {
      var index=queue.findIndex(i=>i.id==item.id);
      queue.splice(index, 1);
    }
  },
}


export default upload;
